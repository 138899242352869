import React, { useContext, useEffect } from 'react'
import {
  GridList,
  GridListTile,
  Select,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import AuthCliente from '../../services/AuthCliente'
import Swal from 'sweetalert2'
import errores from '../../services/errores'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Reaptcha from 'reaptcha'
import ApiGeneral from '../../services/ApiGeneral'
import KeycloakService from '../../services/KeycloakService'
import requests from '../../actions/actions'
import DireccionCard from '../../components/Direccion/DireccionCard'

const useStyles = makeStyles(() => ({
  btncancelar: {
    background: '#eeeeee',
    color: '#003462',
    backgroundColor: '#eeeeee!important',
  },
  btnsubnmit: {
    background: '#003462',
    color: '#ffff',
    backgroundColor: '#003462!important',
  },
  DiAction: {
    paddingRight: '2rem',
  },
  error: {
    color: '#ff0500',
  },
}))

const EditarDireccion = ({
  direccion,
  getDirecciones,
  handleCloseDirecciones,
  setType,
  setMessage,
  setSBOpen,
}) => {
  let dir = direccion.calle

  const [selecteds, setSelecteds] = React.useState({
    Pais: '',
    Estado: '',
    Municipio: '',
    Colonia: '',
    ColoniaId: direccion.coloniaId,
  })

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [verified, setVerified] = React.useState(false)
  const [tipoCliente, setTipoCliente] = React.useState([])
  const [municipio, setMunicipio] = React.useState([])
  const [CP, setCP] = React.useState(direccion.cp)
  const [_response, setResponse] = React.useState()
  const [colonia, setColonia] = React.useState([])
  const [estado, setEstado] = React.useState([])

  const [Pais, setPais] = React.useState([])
  const validationSchema = yup.object({
    Colonia: yup.string().required('El campo colonia es requerido.'),
    NoExt: yup.string().required('El campo número exterior es requerido.'),
    Calle: yup.string().required('El campo calle es requerido.'),
    CP: yup
      .string()
      .min(5, 'El C.P. debe tener un mínimo de 5 caracteres.')
      .max(5, 'El C.P. debe tener un máximo de 5 caracteres.')
      .required('El campo C.P. es requerido.'),
  })
  const [direcciones, setDireccion] = React.useState({
    Calle: '',

    ClienteId: null,
    ColoniaId: 0,
    NoExt: '',
    NoInt: '',
    CP: '',
    Colonia: '',
    Municipio: '',
    activo: true,
    Principal: true,
    UsuarioCreoId: null,
    idClienteDireccion: direccion.idClienteDireccion,
  })
  const validate = values => {
    const errors = {}
    var count = CP.length
    if (count < 5) {
      errors.CP = 'El C.P. debe tener un mínimo de 5 caracteres.'
    }
    if (count > 5) {
      errors.CP = 'El C.P. debe tener un máximo de 5 caracteres.'
    }
    // if (!values.rfc) {
    //   errors.rfc = 'El campo RFC es requerido.';
    // } else if (/^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/.test(values.rfc)) {
    //   errors.email = 'Invalid email address';
    // }
    return errors
  }
  let usuario = KeycloakService.getUserData()

  const formik = useFormik({
    initialValues: {
      ClienteId: usuario.id,
      idClienteDireccion: direccion.idClienteDireccion,

      //ClienteId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315",
      ColoniaId: direccion.coloniaId,
      Calle: direccion.calle,
      NoExt: direccion.noExt,
      NoInt: direccion.noInt,
      CP: '',
      Pais: selecteds.Pais,
      Estado: selecteds.Estado,
      Municipio: selecteds.Municipio,
      Colonia: direccion.colonia,
      activo: direccion.activo,
      Principal: direccion.principal,
      //UsuarioCreoId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315"
      UsuarioCreoId: usuario.id,
    },

    validate,

    validationSchema: validationSchema,
    onSubmit: values => {
      var editDir = {
        ClienteId: usuario.id,
        idClienteDireccion: direccion.idClienteDireccion,

        //ClienteId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315",
        ColoniaId: parseInt(selecteds.ColoniaId),
        Calle: values.Calle,
        NoExt: values.NoExt,
        NoInt: values.NoInt,
        CP: CP,
        Municipio: selecteds.Municipio,
        //Colonia: selecteds.Colonia,
        activo: values.activo,
        Principal: values.Principal,
        //UsuarioCreoId:"64be9460-b84b-4ee7-a7aa-2d2eece6a315"
        UsuarioCreoId: usuario.id,
      }
      ApiGeneral.editeDir(editDir)
        .then(r => {
          handleClose()
          if (setSBOpen) setSBOpen(true)
          if (setMessage) setMessage('Los datos se han actualizado con éxito.')
          if (setType) setType('success')
          if (getDirecciones) getDirecciones()
          console.info('La dirección se ha actualizado con éxito', r)
        })
        .catch(e => {
          handleClose()
          if (setSBOpen) setSBOpen(true)
          if (setMessage) setMessage(errores.handleError('error', e))
          if (setType) setType('error')
        })
    },
  })
  const getCP = () => {
    formik.values.CP = direccion.cp
    setCP(direccion.cp)
    ApiGeneral.getCP(direccion.cp)
      .then(response => {
        setPais(response.data.paises)
        setEstado(response.data.estados)
        setMunicipio(response.data.municipios)
        setColonia(response.data.colonias)
      })
      .catch(error => {})
  }
  useEffect(() => {
    getCP()
  }, [])
  const handlePais = event => {
    selecteds.Pais = event.currentTarget.value
    setSelecteds({ ...selecteds, Pais: event.target.value })
    ApiGeneral.getEstadoPais(selecteds.Pais)
      .then(response => {
        setEstado(response.data.estados)
      })
      .catch(error => {
        console.error('error data estado:' + error)
      })
  }
  const handleEstado = event => {
    formik.values.Colonia = ''

    selecteds.Colonia = ''
    selecteds.ColoniaId = ''
    selecteds.Estado = event.currentTarget.value
    setSelecteds({ ...selecteds, Estado: event.target.value })
    ApiGeneral.getMunicipoEstado(event.currentTarget.value)
      .then(response => {
        setMunicipio(response.data.municipios)
      })
      .catch(error => {
        console.error('error data  municipio:' + error)
      })
  }
  const handleMunicipio = event => {
    selecteds.Municipio = event.currentTarget.value
    formik.values.Colonia = ''

    selecteds.Colonia = ''
    selecteds.ColoniaId = ''
    setSelecteds({ ...selecteds, Municipio: event.target.value })
    ApiGeneral.getColoniasByMunicipios(selecteds.Municipio)
      .then(response => {
        setColonia(response.data.colonias)
      })
      .catch(error => {
        //console.log("error data colonia"+ error);
      })
  }
  const handleColonia = event => {
    //   selecteds.ColoniaId = event.currentTarget.value;
    selecteds.ColoniaId = event.currentTarget.value
    formik.values.Colonia = event.currentTarget.value

    setSelecteds({ ...selecteds, Colonia: event.target.value })

    ApiGeneral.getColonias(event.currentTarget.value)
      .then(response => {
        setCP(response.data.cp)
        selecteds.CP = response.data.cp
      })
      .catch(error => {
        console.error('error data colonias')
      })
  }
  const handleText = event => {
    setDireccion({ ...direcciones, [event.target.name]: event.target.value })
  }
  /*const handleMunicipio = (event) => {
  // props.setOpenLoadingScreen()
  selecteds.Municipio = event.currentTarget.value;
    ApiGeneral.getColoniasByMunicipios(event.currentTarget.value)
        .then((response) => {
            setColonia(response.data.colonias)
        }).catch((error) => {
        })
}*/
  const handleCalle = event => {
    // props.setOpenLoadingScreen()
    formik.Calle = event.currentTarget.value
  }
  /*const getDataByCP = (event) => {
    if (event.currentTarget.value != formik.CP) {
        getLocation(event.currentTarget.value)
    }
  }*/
  const getDataByCP = event => {
    formik.values.Colonia = ''

    selecteds.Colonia = ''
    selecteds.ColoniaId = ''
    // selecteds.CP = event.currentTarget.value
    setCP(event.currentTarget.value)

    ApiGeneral.getCP(event.currentTarget.value)
      .then(response => {
        setPais(response.data.paises)
        setEstado(response.data.estados)
        setMunicipio(response.data.municipios)
        setColonia(response.data.colonias)
        setSelecteds({
          Pais: response.data.idPais,
          Estado: response.data.idEstado,
          Municipio: response.data.idMunicipio,
          // Colonia:response.data.idColonia
        })
        // setDatosFacturacion({ ...props.datosFacturacion, 'coloniaId': response.idColonia });
      })
      .catch(error => {})
  }

  const getPais = () => {
    ApiGeneral.getPais()
      .then(response => {
        console.info('data pais:' + response.data.paises)
        setPais(response.data.paises)
      })
      .catch(error => {
        console.error('error data pais:' + error)
      })
  }

  /*const handlePais = (event) => {
    //  props.setOpenLoadingScreen()
      selecteds.Pais = event.currentTarget.value;
      ApiGeneral.getEstadoPais(event.currentTarget.value)
          .then((response) => {
             // setEstado(response.data.estados)
          }).catch((error) => {
          })
  }*/
  const getLocation = cp => {
    //selecteds.CP = cp
    //formik.values.CP= cp;
    ApiGeneral.getCP(cp)
      .then(response => {
        setResponse(response)
        // setPais(response.data.paises)
        setMunicipio(response.data.municipios)
        setColonia(response.data.colonias)
      })
      .catch(error => {})
  }
  const handleClickOpen = () => {
    AsignarDatos(direccion)

    //  setOpen(true);
  }

  const handleClose = () => {
    setSelecteds([])
    setCP('')
    handleCloseDirecciones()
    formik.resetForm()
    //  setOpen(false);
    if (getDirecciones) getDirecciones()
  }
  const handleChangeActive = event => {
    formik.values.Principal = event.target.checked
    setDireccion({ ...direcciones, [event.target.name]: event.target.checked })
  }
  const handleChangeActiveDir = event => {
    formik.values.activo = event.target.checked
    setDireccion({ ...direcciones, [event.target.name]: event.target.checked })
  }
  const changeCP = event => {
    formik.values.CP = event.currentTarget.value
    setCP(event.currentTarget.value)
  }
  const AsignarDatos = objeto => {
    setDireccion({
      ...direcciones,
      Calle: objeto === null ? '' : objeto.Calle,
      NoExt: objeto === null ? '' : objeto.NoExt,
      NoInt: objeto === null ? '' : objeto.NoInt,
      CP: objeto === null ? '' : objeto.CP,
      Colonia: objeto === null ? '' : objeto.Colonia,
      ColoniaId: objeto === null ? '' : objeto.ColoniaId,
      Municipio: objeto === null ? '' : objeto.Municipio,
      activo: objeto === null ? '' : objeto.activo,

      Principal: objeto === null ? '' : objeto.Principal,
      UsuarioCreoId: objeto === null ? '' : objeto.UsuarioCreoId,
      ClienteId: objeto === null ? '' : objeto.ClienteId,
      idClienteDireccion: objeto === null ? '' : objeto.idClienteDireccion,
    })
  }
  // getLocation(direccion.cp);
  //selecteds.Municipio = direccion.municipio;

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        label='Calle*'
        margin='dense'
        id='Calle'
        name='Calle'
        type='text'
        fullWidth
        onChange={formik.handleChange}
        value={formik.values.Calle}
        onBlur={formik.handleBlur}
        error={formik.touched.Calle && Boolean(formik.errors.Calle)}
        helperText={formik.touched.Calle && formik.errors.Calle}
      />
      {/* <span style={{color: "red"}}>{errores.error["email"]}</span> */}
      <TextField
        margin='dense'
        id='NoExt'
        name='NoExt'
        label='No. Ext*'
        placeholder='No. Ext'
        type='text'
        fullWidth
        onChange={formik.handleChange}
        value={formik.values.NoExt}
        onBlur={formik.handleBlur}
        error={formik.touched.NoExt && Boolean(formik.errors.NoExt)}
        helperText={formik.touched.NoExt && formik.errors.NoExt}
      />
      <TextField
        margin='dense'
        id='NoInt'
        name='NoInt'
        label='No. Int'
        placeholder='No. Int'
        type='text'
        fullWidth
        onChange={formik.handleChange}
        value={formik.values.NoInt}
        onBlur={formik.handleBlur}
        error={formik.touched.NoInt && Boolean(formik.errors.NoInt)}
        helperText={formik.touched.NoInt && formik.errors.NoInt}
      />
      {/* <span style={{color: "red"}}>{errores.error["nombre"]}</span> */}

      <TextField
        style={{ marginTop: '10px' }}
        label='C.P.*'
        fullWidth
        name='CP'
        id='CP'
        type='number'
        value={CP}
        onChange={getDataByCP}
        onBlur={formik.handleBlur}
        error={formik.touched.CP && Boolean(formik.errors.CP)}
        helperText={formik.touched.CP && formik.errors.CP}
      />
      <FormControl
        style={{ marginTop: '10px' }}
        fullWidth
        className={classes.formControl}
      >
        <InputLabel htmlFor='cmbPais'>País*</InputLabel>
        <Select
          native
          label='País*'
          inputProps={{
            name: 'Pais',
            id: 'Pais',
          }}
          onChange={handlePais}
          value={selecteds.Pais}
          onBlur={formik.handleBlur}
        >
          <option aria-label='None' value='  '>
            México - MX
          </option>
          {Pais.map((object, index) => (
            <option value={object.idPais}>
              {object.nombre} - {object.clavePais}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: '10px' }}
        fullWidth
        className={classes.formControl}
      >
        <InputLabel htmlFor='cmbEstado'>Estado*</InputLabel>
        <Select
          native
          label='Estado*'
          inputProps={{
            name: 'Estado',
            id: 'Estado',
          }}
          value={selecteds.Estado}
          onChange={handleEstado}
          onBlur={formik.handleBlur}
        >
          <option aria-label='None' value='  '>
            Nuevo León
          </option>
          {estado.map((object, index) => (
            <option value={object.idEstado}>
              {object.nombre} - {object.claveEstado}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: '10px' }}
        fullWidth
        className={classes.formControl}
      >
        <InputLabel htmlFor='cmbMpio'>Municipio*</InputLabel>
        <Select
          native
          label='Municipio*'
          inputProps={{
            name: 'Municpio',
            id: 'Municipio',
          }}
          value={selecteds.Municipio}
          onChange={handleMunicipio}
          onBlur={formik.handleBlur}
        >
          <option aria-label='None' value='  '>
            {direccion.municipio}
          </option>
          {municipio.map((object, index) => (
            <option value={object.idMunicipio}>{object.nombre}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{ marginTop: '10px' }}
        fullWidth
        className={classes.formControl}
      >
        <InputLabel htmlFor='cmbColonia'>Colonia*</InputLabel>
        <Select
          native
          label='Colonia*'
          inputProps={{
            name: 'Colonia',
            id: 'Colonia',
          }}
          value={selecteds.Colonia}
          onChange={handleColonia}
          onBlur={formik.handleBlur}
          error={formik.touched.Colonia && Boolean(formik.errors.Colonia)}
          helperText={formik.touched.Colonia && formik.errors.Colonia}
        >
          <option aria-label='None' value='  '>
            {selecteds.Colonia == '' ? direccion.colonia : ''}
          </option>
          {colonia.map((object, index) => (
            <option value={object.idColonia}>{object.nombre}</option>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={formik.values.activo}
            onChange={handleChangeActiveDir}
            onBlur={formik.handleBlur}
            name='activo'
          />
        }
        label='Activo'
        labelPlacement='top'
      />
      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={formik.values.Principal}
            onChange={handleChangeActive}
            onBlur={formik.handleBlur}
            name='Principal'
          />
        }
        label='Principal'
        labelPlacement='top'
      />

      <div style={{ textAlign: 'right' }}>
        <Button
          autoFocus
          onClick={handleClose}
          variant='outlined'
          className={classes.btncancelar}
        >
          Cancelar
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          type='submit'
          variant='contained'
          className={classes.btnsubnmit}
          value='Registrar'
          color='primary'
        >
          Guardar
        </Button>
      </div>
    </form>
  )
}

export default EditarDireccion
