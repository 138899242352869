import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function MySnackBar(props) {
  const classes = useStyles()
  const [state] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  })
  const { vertical, horizontal } = state

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.Open}
        autoHideDuration={3500}
        onClose={props.HandleClose}
      >
        <Alert onClose={props.HandleClose} severity={props.Type}>
          {props.Message}
        </Alert>
      </Snackbar>
    </div>
  )
}
