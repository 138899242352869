import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import mask from '../Banner.css'
import { Card, CardMedia, Grid } from '@material-ui/core'
import { Image } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function BannerDesk(props) {
  let items = []
  props.item.map((item, index) => {
    items.push(
      <div key={item.Id}>
        <LazyLoadImage src={item.Image} />
      </div>,
    )
  })

  const sliderConfig = {
    autoPlay: true,
    infiniteLoop: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    showStatus: false,
    showThumbs: false,
    showIndicators: true,
    interval: 8000,
  }

  return (
    <Carousel
      className={props.cl}
      autoPlay={sliderConfig.autoPlay}
      infiniteLoop={sliderConfig.infiniteLoop}
      showThumbs={sliderConfig.showThumbs}
      showStatus={sliderConfig.showStatus}
      showIndicators={sliderConfig.showIndicators}
      interval={sliderConfig.interval}
    >
      {items}
    </Carousel>
  )
}

export default BannerDesk
