import {
  ADDFROMREDIS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_ALL_FROM_CART,
  DECREMENT,
  INCREMENT,
  SET_LOADING_INITIAL,
  ADD_TO_FILTER,
  CLEAR_ALL_FROM_FILTER,
  TIPO_CAMBIO,
  REMOVE_ALL,
  ADDPRICE,
} from './types'
import Carrito from '../actions/carritoActions'
import { getWishList } from '../actions/resultActions'
import KeycloakService from '../services/KeycloakService'

// const setFilter = (state, filter) => {
//   debugger
//   return { ...state, filterv2: filter };
// };
// const Details = () => {
//   const [moneda, setMoneda] = useState([])
//   const getMonedas = async () => {
//     const moned = await getMoneda()
//     setMoneda(moned.data)

//     window.localStorage.setItem('monedasup', moned.data.abreviatura)
//   }
//   useEffect(() => {
//     //  getPromos();
//     getMonedas()
//   }, [])
//   return moneda
// }

const addProductToFilter = (state, filter) => {
  // Verifica si el filtro ya existe en el array
  const exists = state.filters.some(i => i._id === filter._id)

  // Si el filtro ya existe, devuelve el estado original sin cambios
  if (exists) {
    return state
  }

  // Si no existe, devuelve un nuevo estado con el filtro agregado
  return {
    ...state,
    filters: [...state.filters, filter],
  }
}

const addProductToCart = (state, product) => {
  // const inve = product.maxQuantity

  const copy = [...state.carts]
  const curItemIndex = copy.findIndex(i => i.product._id === product._id)
  window.localStorage.setItem('monedaCambio', product.moned)

  if (curItemIndex < 0) {
    copy.push({ product, quantity: 1 })
  } else {
    const copyItem = { ...copy[curItemIndex] }
    copyItem.quantity++

    copyItem.product.infoAdicional = product.infoAdicional
    copy[curItemIndex] = copyItem
  }
  var valor
  let _subTotal
  if (product.moned === 'USD') {
    valor = copy.reduce(
      (totalCalories, i) =>
        totalCalories + i.product.price.toFixed(2) * i.quantity,
      0
    )
    _subTotal = (valor / 1.16).toFixed(2)
  } else {
    valor = copy.reduce(
      (totalCalories, i) =>
        totalCalories + i.product.price.toFixed(2) * i.quantity,
      0
    )
    _subTotal = (valor / 1.16).toFixed(2)
  }

  const subtotal = []
  subtotal.push(_subTotal)

  const iva = []
  iva.push((valor - _subTotal).toFixed(2))

  const total = []
  total.push(valor)

  Carrito.setCarrito(copy)

  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const wish = async id => {
  const usuario = KeycloakService.getUserData()
  const MyObject = { idProducto: id, idusuario: usuario.id, activo: true }
  const JsonArray2 = JSON.stringify(MyObject)
  const result = await getWishList(JsonArray2)
}

const removeProductFromCart = (state, action) => {
  const { productID, moneda } = action.payload

  const copy = [...state.carts]
  const curItemIndex = copy.findIndex(i => i.product._id === productID)
  const curItem = { ...copy[curItemIndex] }
  curItem.quantity = 0

  if (curItem.quantity <= 0) {
    copy.splice(curItemIndex, 1)
  } else {
    copy[curItemIndex] = curItem
  }
  let val
  if (moneda === 'USD') {
    val = copy.reduce(
      (totalCalories, i) =>
        totalCalories +
        (i.product.price * i.product.tipoCambio).toFixed(2) * i.quantity,
      0
    )
  } else {
    val = copy.reduce(
      (totalCalories, i) =>
        totalCalories + i.product.price.toFixed(2) * i.quantity,
      0
    )
  }

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = []
  subtotal.push(_subTotal)

  const iva = []
  iva.push((val - _subTotal).toFixed(2))

  const total = []
  total.push(val)

  Carrito.setCarrito(copy)

  if (
    state.carts[curItemIndex].product.wishList === true &&
    state.carts[curItemIndex].product.pedidos === true
  ) {
    wish(productID)
  }
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const removeAllProductsFromCart = state => {
  return {
    ...state,
    carts: [],
    subtotal: [],
    iva: [],
    total: [],
  }
}

const decrementCounter = (state, productID) => {
  let TC = state.getTipoCambio
  const copy = [...state.carts]
  const curItemIndex = copy.findIndex(i => i.product._id === productID)
  const copyItem = { ...copy[curItemIndex] }
  if (copyItem.quantity == 1) {
    return { ...state, carts: copy }
  }
  copyItem.quantity--
  copy[curItemIndex] = copyItem

  const val = copy.reduce(
    (totalCalories, i) =>
      totalCalories + i.product.price.toFixed(2) * i.quantity,
    0
  )

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = []
  subtotal.push(_subTotal)

  const iva = []
  iva.push((val - _subTotal).toFixed(2))

  const total = []
  total.push(val)

  Carrito.setCarrito(copy)

  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const incrementCounter = (state, productID) => {
  let TC = state.getTipoCambio
  const copy = [...state.carts]
  const curItemIndex = copy.findIndex(i => i.product._id === productID)
  const copyItem = { ...copy[curItemIndex] }
  //const abreviatura = window.localStorage.getItem('monedas')

  copyItem.quantity++
  copy[curItemIndex] = copyItem
  const val = copy.reduce(
    (totalCalories, i) =>
      totalCalories + i.product.price.toFixed(2) * i.quantity,
    0
  )

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = []
  subtotal.push(_subTotal)

  const iva = []
  iva.push((val - _subTotal).toFixed(2))

  const total = []
  total.push(val)

  Carrito.setCarrito(copy)

  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const clearFilter = state => {
  // Solo devolver un nuevo estado si filters no está vacío
  if (state.filters.length === 0) {
    return state
  }

  return {
    ...state,
    filters: [],
  }
}

const clearCart = state => {
  //Carrito.setCarrito([]);
  // window.localStorage.removeItem('carritoID')
  return {
    ...state,
    carts: [],
  }
}

const setLoadingInitial = state => {
  return {
    ...state,
    loadingInitial: false,
  }
}

const addTipoCambio = (state, tipoCambio) => {
  return { ...state, getTipoCambio: tipoCambio }
}

const addPrice = (state, product, cambio) => {
  const num = Number(cambio) // Convertir el tipo de cambio a número
  const copy = [...state.carts] // Copia del carrito actual

  const curItemIndex = copy.findIndex(i => i.product._id === product._id)

  if (curItemIndex < 0) {
    copy.push({ product, quantity: product.quantity })
  } else {
    copy[curItemIndex] = { product, quantity: product.quantity }
  }

  // Calcular el valor total basado en el tipo de moneda (USD o MXN)
  const factor = product.moned === 'USD' ? num : 1 / num

  const valor = copy.reduce(
    (total, item) => total + item.product.price * factor * item.quantity,
    0
  )

  const _subTotal = (valor / 1.16).toFixed(2)
  const subtotal = [_subTotal]
  const iva = [(valor - _subTotal).toFixed(2)]
  const total = [valor]

  // Devolver el nuevo estado con el carrito, subtotal, iva y total actualizados
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const addRedisData = (state, product, monedaC, mone) => {
  // Copiar el producto para evitar mutar el original
  const updatedProduct = { ...product }

  // Ajustar el precio del producto según el tipo de moneda
  if (monedaC !== mone) {
    if (monedaC === 'MXN') {
      updatedProduct.price = updatedProduct.price / updatedProduct.tipoCambio
    } else {
      updatedProduct.price = updatedProduct.price * updatedProduct.tipoCambio
    }
  }

  // Crear una copia del carrito
  const copy = [...state.carts]
  const curItemIndex = copy.findIndex(i => i.product._id === updatedProduct._id)

  // Si el producto no está en el carrito, lo agregamos
  if (curItemIndex < 0) {
    copy.push({ product: updatedProduct, quantity: updatedProduct.quantity })
  } else {
    // Si ya está en el carrito, actualizamos la cantidad
    copy[curItemIndex] = {
      product: updatedProduct,
      quantity: updatedProduct.quantity,
    }
  }

  // Calcular el valor total, subtotal e IVA
  const valor = copy.reduce(
    (total, item) => total + parseFloat(item.product.price) * item.quantity,
    0
  )

  const _subTotal = (valor / 1.16).toFixed(2)
  const subtotal = [_subTotal]

  const iva = [(valor - _subTotal).toFixed(2)]
  const total = [valor]

  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total }
}

const globalReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return addProductToCart(state, action.payload)
    case REMOVE_FROM_CART:
      return removeProductFromCart(state, action)
    case CLEAR_ALL_FROM_CART:
      return clearCart(state)
    case DECREMENT:
      return decrementCounter(state, action.payload)
    case INCREMENT:
      return incrementCounter(state, action.payload)
    case ADDFROMREDIS:
      return addRedisData(
        state,
        action.payload.product,
        action.payload.monedaC,
        action.payload.mone
      )
    case SET_LOADING_INITIAL:
      return setLoadingInitial(state)
    case ADD_TO_FILTER:
      return addProductToFilter(state, action.payload)
    case CLEAR_ALL_FROM_FILTER:
      return clearFilter(state)
    case TIPO_CAMBIO:
      return addTipoCambio(state, action.payload)
    case REMOVE_ALL:
      return removeAllProductsFromCart(state)
    case ADDPRICE:
      return addPrice(state, action.payload.product, action.payload.cambio)
    default:
      return state
  }
}

export default globalReducer
