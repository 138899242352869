import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import GlobalState from '../src/store/GlobalState'
import { createBrowserHistory } from 'history'
import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/icon.min.css'
// import 'antd/lib/image/style/index.css'

import App from './App'

import StylesProvider from './contexts/styles/stylesProvider'
import GlobalProvider from './contexts/GlobalProvider'
// import Provider from './store2/Provider'

const customHistory = createBrowserHistory()

console.log('Entorno corriendo>> ' + process.env.REACT_APP_BASE_URL)

console.log('Entorno petifu>>' + process.env.NODE_ENV)

ReactDOM.render(
  // <React.StrictMode>
  <GlobalState>
    <GlobalProvider>
      <StylesProvider>
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>
      </StylesProvider>
    </GlobalProvider>
  </GlobalState>,
  // </React.StrictMode>,

  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
