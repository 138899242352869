import React from 'react'
import { Card, CardMedia, Grid } from '@material-ui/core'
// import { COLORS } from '../../../Views/Colores'
import Carousel from 'react-material-ui-carousel'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Image } from 'antd'
function BannerMobile(props) {
  // document.documentElement.style.setProperty('--color-principal', COLORS.black);

  const sliderConfig = {
    autoPlay: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    interval: 8000,
  }

  let items = []
  props.item.map((item, index) => {
    items.push(
      <div style={{ marginTop: '80px' }} key={item.Id}>
        <LazyLoadImage src={item.MobileImage} />
      </div>,
    )
  })

  return (
    <Carousel
      className='mobile-carousel'
      autoPlay={sliderConfig.autoPlay}
      animation={sliderConfig.animation}
      indicators={sliderConfig.indicators}
      timeout={sliderConfig.timeout}
      cycleNavigation={sliderConfig.cycleNavigation}
      navButtonsAlwaysVisible={sliderConfig.navButtonsAlwaysVisible}
      interval={sliderConfig.interval}
      navButtonsAlwaysInvisible={sliderConfig.navButtonsAlwaysInvisible}
    >
      {items}
    </Carousel>
  )
}

export default BannerMobile
