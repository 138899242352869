import React, { useEffect, useState, useContext, lazy } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { Alert } from '@material-ui/lab'

import CheckUser from '../CheckUser'
import StylesContext from '../../contexts/styles/stylesContext'
import GlobalContext from '../../contexts/GlobalContext'
import KeycloakService from '../../services/KeycloakService'

import WhatsAppButton from '../WhatsAppButton/WhatsAppButton'
const NotificacionBar = lazy(() => import('../NotificacionBar/NotificacionBar'))
const Header = lazy(() => import('../../components/Header/HeaderResponsive'))
const Footer = lazy(() => import('../../Views/Footer/FooterMenu'))

const Layout = ({ children }) => {
  const { sectionsState, messageAlert } = useContext(GlobalContext)
  const stylesContext = useContext(StylesContext)
  const location = useLocation()

  const [loaded, setLoaded] = useState(false)

  const notificacionBar = sectionsState.find(item => item.orden === 1)
  const protectedRoutes = ['/MiCuenta', '/MisDirecciones', '/orden', '/pago'] // Rutas protegidas que requieren autenticación
  const currentPath = location.pathname

  useEffect(() => {
    const validateUser = async () => {
      try {
        // Actualiza el token antes de cualquier validación
        await KeycloakService.updateToken(() => {
          // Verifica si el usuario sigue logueado tras actualizar el token
          if (KeycloakService.isLoggedIn()) {
            return CheckUser.ValidaExistencia()
          }
        })
      } catch (error) {
        console.error('Error actualizando el token o validando usuario:', error)
      } finally {
        setLoaded(true)
      }
    }

    if (protectedRoutes.some(route => currentPath.startsWith(route))) {
      // if (KeycloakService.isLoggedIn()) {
      validateUser()
      // } else {
      //   setLoaded(true)
      // }
    } else {
      setLoaded(true)
    }
  }, [currentPath])

  if (!loaded) {
    return <Segment basic style={{ height: 500, width: '100%' }} loading />
  }

  // Redirige a login si la ruta es protegida y el usuario no está autenticado
  if (
    !KeycloakService.isLoggedIn() &&
    protectedRoutes.some(route => window.location.pathname.startsWith(route))
  ) {
    return <Redirect to='/login' />
  }

  return (
    <>
      {messageAlert.active && (
        <div
          className={`alert-container ${messageAlert.active ? 'active' : ''}`}
        >
          <Alert severity={messageAlert.type}>{messageAlert.message}</Alert>
        </div>
      )}

      {notificacionBar && notificacionBar.activo && (
        <NotificacionBar identificador={notificacionBar.identificador} />
      )}

      <Header
        logop={stylesContext.logo}
        colors={stylesContext.black}
        nav={false}
      />

      <div className='content' style={{ width: '100%', maxWidth: '100vw' }}>
        {children}
      </div>

      <Footer />

      <WhatsAppButton />
    </>
  )
}

export default Layout
