import Swal from 'sweetalert2'

export class alerts {
  static success(texto) {
    Swal.fire(texto, '', 'success')
  }
  static warning(texto) {
    Swal.fire(texto, '', 'warning')
  }
  static info(texto) {
    Swal.fire(texto, '', 'info')
  }
  static error(texto) {
    Swal.fire(texto, '', 'error')
  }
}
