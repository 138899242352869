import React, { Component } from 'react'
import Keycloak from 'keycloak-js'
import Home from './../Views/Home/Home'
import MenuDetalle from '../components/Login/MenuDetalle'
class Secured extends Component {
  constructor(props) {
    super(props)
    this.state = { keycloak: null, authenticated: false }
  }

  componentDidMount() {
    const keycloak = Keycloak({
      realm: 'IntegrinoxPublic',
      url: 'https://identityaxsis.eastus.cloudapp.azure.com/auth/',
      clientId: 'da220321-11ca-431b-b172-007e7c271657',
      authenticated: true,
    })
    keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
      this.setState({ keycloak: keycloak, authenticated: authenticated })
    })
  }
  render() {
    if (this.state.keycloak) {
      if (this.state.authenticated)
        return (
          <div>
            <Home></Home>
          </div>
        )
      else return <div>Unable to authenticate!</div>
    }
    return <div>Initializing Keycloak...</div>
  }
}
export default Secured
