import React from 'react'
// import Header from '../Header/Header'
import BannerExample from '../../components/Banner/BannerExample'
import Grid from '@material-ui/core/Grid'
import Ofertas from '../../components/Ofertas/Ofertas'
import Cvendido from '../../components/Vendido/Cvendido'
import General from '../General/General'
import Footer from '../Footer/Footer'

function Home() {
  return (
    <Grid container>
      <Grid item lg={12} md={12} xs={12}>
        <BannerExample></BannerExample>
      </Grid>
      <Ofertas />

      <Cvendido />
      <General></General>
    </Grid>
  )
}
export default Home
