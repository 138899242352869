import React, { useContext, useEffect, useState, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { v4 as uuidv4 } from 'uuid'
import ReactGA4 from 'react-ga4'
import ReactGA from 'react-ga'
import OneSignal from 'react-onesignal'
import Favicon from 'react-favicon'

import './App.css'
import { getKey } from './actions/resultActions'
import { getCarrito, getTipoCambio } from './actions/carritoActions'
import KeycloakService from './services/KeycloakService'
import Secured from './components/Secured'

import Context from './store/Context'
import GlobalContext from './contexts/GlobalContext'
import StylesContext from './contexts/styles/stylesContext'

import Layout from './components/Layout/Layout'
import HemmetWrapping from './components/HemmetWrapping'

const Login = lazy(() => import('./Views/Login/Login'))
const MiPlantilla = lazy(() => import('./components/Plantillas/MiPlantilla'))
const Products = lazy(() => import('./components/Result/Search_Result'))
const HomeT = lazy(() => import('./components/test/PlantillaElement'))
const Registro = lazy(() => import('./Views/Registro/Registrar'))
const NotFound = lazy(() => import('./Views/NotFound/NotFound'))
const Orden = lazy(() => import('./components/Orden/Orden'))
const MisCompras = lazy(() =>
  import('./components/Cuenta/MisCompras/Miscompras')
)
const MiCuenta = lazy(() => import('./components/DetalleCuenta/MiCuenta'))
const Direccion = lazy(() => import('./components/Direccion/AgregarDireccion'))
const HistorialPedidos = lazy(() =>
  import('./components/Cuenta/Historial/HistorialPedidos')
)
const Contacto = lazy(() => import('./components/Contacto/Contacto'))
// const Plantilla = lazy(() => import('./components/Plantillas/Plantillas'))
const MisDirecciones = lazy(() => import('./components/Direccion/Direccion'))
const ArticulosCarrito = lazy(() =>
  import('./components/ProcederPago/ArticulosCarrito')
)
const TarjetaEnviada = lazy(() => import('./components/Tarjeta/TarjetaEnviada'))
const Details = lazy(() => import('./components/Result/ProductDetail'))
const PagarTarjeta = lazy(() => import('./components/Tarjeta/PagarTarjeta'))
const Tarjetas = lazy(() => import('./components/Tarjeta/TarjetaRegalo'))
const Blogs = lazy(() => import('./components/test/Blog'))
const PagoResult = lazy(() => import('./Views/Pago/PagoResult'))

const App = () => {
  const { notificacionesState } = useContext(GlobalContext)
  // const { styles } = useStyles()
  const context = useContext(Context)
  const stylesContext = useContext(StylesContext)
  const logo = stylesContext.logo

  const [keycloakInitialized, setKeycloakInitialized] = useState(false)

  let idCarrito = window.localStorage.getItem('carritoID')

  if (!idCarrito) {
    // if (!KeycloakService.isLoggedIn()) {
    idCarrito = uuidv4()
    window.localStorage.setItem('carritoID', idCarrito)
    // }
  }

  useEffect(() => {
    const keycloakInstance = KeycloakService.getAll() // Obtiene Keycloak directamente
    if (keycloakInstance) {
      setKeycloakInitialized(true) // Actualiza el estado una vez obtenido
    }
  }, [])

  useEffect(() => {
    if (!notificacionesState.valor) return

    OneSignal.init({
      appId: notificacionesState?.valor,
    })
  }, [notificacionesState])

  // Analytics
  useEffect(() => {
    const getMenuAdmin = async () => {
      const responses = await getKey('9d7e554f-32c5-40c6-bdcf-a558f7a28f9c')

      const TRACKING_ID = responses.data.valor
      ReactGA.initialize(TRACKING_ID)
      ReactGA4.initialize(TRACKING_ID)
    }

    getMenuAdmin()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let TC = 1

        const r = await getTipoCambio()
        console.log('getTipoCambio', r.data.tipoCambio)
        context.addTipoCambio(r.data.tipoCambio)
        context.filterv2.espesorId = []
        context.filterv2.medidaId = []
        context.filterv2.tipoAceroId = []

        TC = r.data.tipoCambio

        const k = await getCarrito(idCarrito)
        const carrito = k.data

        carrito?.items?.forEach(i => {
          context.addCartFromRedis({
            quantity: i.cantidad,
            maxQuantity: i.maxCantidad,
            _id: i.id,
            price: i.precio,
            images: i.imagen,
            title: i.producto,
            peso: i.peso,
            familiaId: i.familiaId,
            infoAdicional: i.infoAdicional,
            tipoCambio: TC,
          })
        })
      } catch (error) {
        console.error('Error fetching data: ', error)
      }
    }

    console.log('context', context)
    console.log('idCarrito', idCarrito)
    fetchData()
  }, [idCarrito])

  const actualizaElCarrito = async () => {
    let usuario
    if (KeycloakService.isLoggedIn()) {
      usuario = await KeycloakService.getUserData()
    }

    if (usuario) {
      window.localStorage.setItem('carritoID', usuario.email)
    }

    var response = await getCarrito(window.localStorage.getItem('carritoID'))

    var carrito = response.data

    carrito?.items?.forEach(i => {
      context.addCartFromRedis({
        quantity: i.cantidad,
        maxQuantity: i.maxCantidad,
        _id: i.id,
        price: i.precio,
        images: i.imagen,
        title: i.producto,
        peso: i.peso,
        familiaId: i.familiaId,
        infoAdicional: i.infoAdicional,
        tipoCambio: context.getTipoCambio,
      })
    })
  }

  if (!keycloakInitialized) {
    return <div>Cargando...</div>
  }

  return (
    <ReactKeycloakProvider authClient={KeycloakService.getAll()}>
      <BrowserRouter>
        <HemmetWrapping />
        {logo ? <Favicon url={logo} /> : ''}
        {/* <Suspense fallback={<div></div>}></Suspense> */}

        <Switch>
          <Layout>
            {/* public routes */}
            <Route exact path='/' component={HomeT} />
            <Route exact path='/login' component={Login} />
            <Route
              exact
              path='/products/:idm/p'
              render={() => <Details actualizaElCarrito={actualizaElCarrito} />}
            />
            <Route exact path='/blog/:idm/p' render={() => <Blogs />} />
            <Route exact path='/:idm' render={() => <MiPlantilla />} />
            <Route exact path='/products' component={Products} />
            <Route exact path='/products/:search/:by/ls' component={Products} />
            <Route exact path='/Secured' component={Secured} />
            <Route exact path='/compras' component={MisCompras} />
            <Route exact path='/AgregarDireccion' component={Direccion} />
            <Route
              exact
              path='/tienda/articulos/carrito/pago'
              component={ArticulosCarrito}
            />
            <Route path='/tienda/tarjetas' exact component={Tarjetas} />
            <Route path='/tarjetas/:id/t' exact component={PagarTarjeta} />
            <Route path='/enviado/:id/:idm' exact component={TarjetaEnviada} />
            {/* <Route path='/:id/:name' exact component={Plantilla} /> */}
            <Route path='/Contacto/:id/:nombre' exact component={Contacto} />
            <Route exact path='/historial' component={HistorialPedidos} />
            <Route exact path='/registro/clikalo/tienda' component={Registro} />
            {/* private routes */}
            <Route exact path='/MiCuenta' component={MiCuenta} />
            <Route exact path='/MiCuenta/:path' component={MiCuenta} />
            <Route exact path='/MisDirecciones' component={MisDirecciones} />
            <Route exact path='/orden/:pedido' component={Orden} />
            <Route
              exact
              path='/orden/tienda/pedido/compra/pago'
              component={Orden}
            />
            <Route
              exact
              path='/orden/:bbva/process/:pedido'
              component={Orden}
            />
            <Route
              exact
              path='/pago/:status/:supplier'
              component={PagoResult}
            />
          </Layout>

          <Route component={NotFound} />
        </Switch>
        {/* <Suspense fallback={<div></div>}></Suspense> */}
      </BrowserRouter>
    </ReactKeycloakProvider>
  )
}

export default App
