import React from 'react'
import Vvendido from '../../Views/Vendido/Vvendido'

function Cvendido(props) {
  return (
    <>
      <Vvendido num={props.num} />
    </>
  )
}

export default Cvendido
