const handleError = errorMsj => {
  switch (errorMsj) {
    case 'User exists with same username':
      return 'El nombre de usuario ya se encuentra registrado.'
    case 'User exists with same username or email':
      return 'El correo especificado ya se encuentra registrado.'
    case 'User exists with same email':
      return 'El correo especificado ya se encuentra registrado.'
    case 'HTTP 401 Unauthorized':
      return 'Acceso no autorizado.'
    case 'Unauthorized':
      return 'Acceso no autorizado.'
    case 'Role with name  already exists':
      return 'El nombre del rol ya se encuentra registrado.'
    default:
      return errorMsj
  }
}

const errores = {
  handleError,
}

export default errores
