import Keycloak from 'keycloak-js'

const _kc = new Keycloak(
  process.env.NODE_ENV === 'production'
    ? '/keycloakPublic.json'
    : process.env.REACT_APP_BASE_URL === 'prd'
    ? '/keycloakPublic.json'
    : '/keycloak.json'
)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = onAuthenticatedCallback => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      flow: 'standard', //'standard'|'implicit'|'hybrid';
      loginInframe: { enable: false },
      enableLogging: true,
    })
    .then(authenticated => {
      if (authenticated) {
        // Guardar el token y la fecha de expiración en localStorage
        const token = _kc.token
        const expirationTime = _kc.tokenParsed.exp * 1000 // Convertir exp a milisegundos

        localStorage.setItem('tokenKc', token)
        localStorage.setItem('tokenExpKc', expirationTime)

        // Llama el callback de autenticación exitosa
        onAuthenticatedCallback()
      }
    })
    .catch(error => {
      console.error('Error al iniciar Keycloak:', error)
    })
}

// Login y Logout
const doLogin = _kc.login

const doLogout = () => {
  // Limpiar el token y la expiración del localStorage
  localStorage.removeItem('tokenKc')
  localStorage.removeItem('tokenExpKc')

  _kc.logout()
}

// Obtener token
const getToken = () => _kc.token

// Establecer manualmente el token
const setToken = token => (_kc.token = token)

// Obtener instancia completa de Keycloak
const getAll = () => _kc

// Verificar si el usuario está autenticado
const isLoggedIn = () => {
  // Intentar obtener el token del localStorage
  let token = localStorage.getItem('tokenKc')
  let tokenExpiration = localStorage.getItem('tokenExpKc')

  // Si no hay token en localStorage, intentamos obtenerlo desde _kc
  if (!token || !tokenExpiration) {
    console.warn(
      'No se encontró token en localStorage, obteniendo desde Keycloak...'
    )

    // Verificar si el token está disponible en _kc
    if (_kc.token && _kc.tokenParsed?.exp) {
      token = _kc.token
      tokenExpiration = _kc.tokenParsed.exp * 1000 // Convertir exp a milisegundos

      // Guardar token y expiración en localStorage
      localStorage.setItem('tokenKc', token)
      localStorage.setItem('tokenExpKc', tokenExpiration)
    } else {
      console.error('No se pudo obtener el token desde Keycloak')
      return false
    }
  }

  // Verificar si el token es válido comprobando la expiración
  const currentTime = new Date().getTime()
  if (currentTime < tokenExpiration) {
    return true // El token es válido
  } else {
    // El token ha expirado, lo eliminamos del localStorage
    localStorage.removeItem('tokenKc')
    localStorage.removeItem('tokenExpKc')
    console.warn('El token ha expirado')
    return false
  }
}

// Actualizar el token antes de que expire
const updateToken = successCallback => {
  _kc
    .updateToken(300) // Renueva el token si faltan menos de 300 segundos
    .then(refreshed => {
      if (refreshed) {
        // Actualizar token y tiempo de expiración en localStorage
        const newToken = _kc.token
        const newExpirationTime = _kc.tokenParsed.exp * 1000

        localStorage.setItem('tokenKc', newToken)
        localStorage.setItem('tokenExpKc', newExpirationTime)

        successCallback()
      }
    })
    .catch(() => {
      _kc.login() // Si no se puede renovar, redirige al login
    })
}

// Obtener nombre de usuario
const getUsername = () => _kc.tokenParsed?.preferred_username

// Obtener datos del usuario
const getUserData = () => {
  if (!_kc.tokenParsed || !_kc.token) {
    return {
      authenticated: false,
    }
  }
  return {
    authenticated: true,
    NombreCompleto: _kc.tokenParsed?.name,
    Nombre: _kc.tokenParsed?.given_name,
    Apellido: _kc.tokenParsed?.family_name,
    email: _kc.tokenParsed?.email,
    userName: _kc.tokenParsed?.preferred_username,
    token: _kc.token,
    refresh_token: _kc.refreshToken,
    roles: _kc.realmAccess,
    roles2: _kc.resourceAccess,
    id: _kc.tokenParsed?.sub,
  }
}

// Verificar si el usuario tiene un rol específico
const hasRole = roles => roles.some(role => _kc.hasRealmRole(role))

// Exportar el servicio de usuario
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  token: _kc.token,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserData,
  setToken,
  getAll,
}

export default UserService
