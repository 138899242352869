import React, { useState, useContext, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { FaRegHeart } from 'react-icons/fa'
import { FaCartPlus } from 'react-icons/fa'
import { FaHeart } from 'react-icons/fa'
import { BsPlusSquare } from 'react-icons/bs'
import { BsPlusSquareFill } from 'react-icons/bs'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import './integrinoxoriginal.css'

import Context from '../../../store/Context'
import GlobalContext from '../../../contexts/GlobalContext'

import Base from '../../../services/Base'
import KeycloakService from '../../../services/KeycloakService'
import {
  getCompare,
  getProductVariant,
  getWishList,
} from '../../../actions/resultActions'

import { useAnalyticsEventTracker } from '../../GoogleAnalitycs/useAnalyticsEventTracker'

const useStyles = makeStyles(Theme => ({
  botom: {
    padding: 5,
    fontSize: '15px',
    borderColor: '#191919',
    backgroundColor: 'white',
  },
  icon: {
    backgroundColor: 'white',
    borderColor: 'white',
    marginLeft: '-20px',
    marginTop: '5px',
  },
  filas: {
    display: 'flex',
  },
  image: {
    borderRadius: 150 / 2,
    overflow: 'hidden',
    borderWidth: 3,
    borderColor: 'red',
  },
  preguntas: {
    width: '63%',
    marginLeft: '26%',
  },
  botonp: {
    marginLeft: '91%',
    marginTop: '-37px',
  },
  botonp2: {
    marginLeft: '845px',
    marginTop: '-37px',
  },
  all: {
    marginLeft: '50px',
  },
  comentarios: {
    width: '63%',
    marginLeft: '25%',
  },
  allcom: {
    marginLeft: '-50px',
  },
  preguntaList: {
    marginLeft: '0%',
    margin: '20px',
  },
  comentList: {
    marginLeft: '0%',
  },
  precios: {
    marginTop: '-27px',
    marginLeft: '59px',
  },
}))

const CardOriginal = ({ addProductToCart, ...props }) => {
  const userData = KeycloakService.getUserData()
  const history = useHistory()
  const classes = useStyles()
  const context = useContext(Context)
  const { setAlert } = useContext(GlobalContext)

  const imgVariante = []
  const img = []
  const [resultResponseImg, setResultResponseImg] = useState(null)
  const getImgVarS = (rutaUbicacion, id, extension) => {
    const urlImg = Base.baseFiles.imgProduct
    const val = urlImg + '/' + rutaUbicacion + '/' + id + extension
    return val
  }
  const [inWishList, setInWishList] = useState(props.icon)
  const [inList, setInList] = useState(props.lists)
  const [resultVariantes, setResultVariantes] = useState([])

  const getLink = ind => {
    console.log(resultVariantes)
    console.log(ind)
    resultVariantes.map((i, index) => {
      if (ind === index) {
        console.log(i.productoIdVariante)
        history.push('/products/' + i.productoIdVariante + '/p')
      }
    })
  }

  const wishEliminar = async id => {
    if (inWishList === true) {
      const usuario = KeycloakService.getUserData()

      const MyObject = { idProducto: id, idusuario: usuario.id, activo: true }
      const JsonArray2 = JSON.stringify(MyObject)
      const result = await getWishList(JsonArray2)
      setInWishList(result.data.activo)
    }
  }

  const wishList = async () => {
    const usuario = KeycloakService.getUserData()
    const MyObject = {
      idProducto: props.idProducto,
      idusuario: usuario.id,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)
    const result = await getWishList(JsonArray2)
    setInWishList(result.data.activo)
  }

  const compareList = async () => {
    const usuario = KeycloakService.getUserData()
    const MyObject = {
      idProducto: props.idProducto,
      idusuario: usuario.id,
      activo: true,
    }
    const JsonArray2 = JSON.stringify(MyObject)

    console.log(JsonArray2)
    const result = await getCompare(JsonArray2)
    setInList(result.data.activo)
  }

  const cargaVariante = async () => {
    const res = await getProductVariant(props.idProducto)
    setResultVariantes(res.data)
    const response = await getProductVariant(props.idProducto)

    imgVariante.push(response.data)

    imgVariante[0].forEach(element => {
      setResultResponseImg(element.productoImagens)
    })

    imgVariante[0].forEach(element => {
      img.push(element.productoIdVariante)
    })
    if (response.data.length === 0) {
      setResultResponseImg(null)
    }
  }

  const gaEventTracker = useAnalyticsEventTracker('Ver articulo')

  useEffect(() => {
    cargaVariante()
  }, [])

  return (
    <div className='product'>
      {props.activoDescuento || props.rebaja ? (
        <div className='tagh'>
          <h3 className='fou'>{props.descuento} %</h3>
        </div>
      ) : (
        <div style={{ background: 'transparent', width: '30%' }}>
          <h3 className='fou'>{props.descuento} %</h3>
        </div>
      )}

      <div className='bucart'>
        <div className=''>
          <Tooltip title={'Añadir al carrito'} placement='bottom'>
            <Button
              onClick={() => {
                wishEliminar(props.idProducto)
                context.addProductToCart({
                  _id: props.idProducto,
                  price:
                    props.rebaja === true || props.activoDescuento === true
                      ? props.descuentoPrecio
                      : props.sprice,
                  images: props.imagenPereview,
                  title: props.nombre.replaceAll(`"`, `''`),
                  peso: props.peso,
                  familiaId: props.categoriaId,
                  infoAdicional: '',
                  maxQuantity: props.inventario,
                  tipoCambio: context.getTipoCambio,
                  moned: props.abrevia,
                  wishList: inWishList,
                  pedidos: true,
                })
                setAlert('El producto se agregado al carrito', 'success')
              }}
            >
              <div>
                <FaCartPlus className='icon' />
              </div>
            </Button>
          </Tooltip>
        </div>

        <div className=''>
          {userData.authenticated ? (
            <Tooltip title={'Mis deseos'} placement='bottom'>
              <Button
                onClick={() => {
                  wishList()
                  if (inWishList) {
                    setAlert(
                      'El producto se elimino de la lista de deseos',
                      'warning'
                    )
                  } else {
                    setAlert(
                      'El producto se agregado a la lista de deseos',
                      'success'
                    )
                  }
                }}
              >
                <div>
                  {inWishList ? (
                    <FaHeart className='myact' />
                  ) : (
                    <FaRegHeart className='icon' />
                  )}
                </div>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={'Debes iniciar sesión'} placement='bottom'>
              <Button onClick={() => {}}>
                <div>
                  <FaRegHeart className='icon' />
                </div>
              </Button>
            </Tooltip>
          )}
        </div>

        <div className=''>
          {userData.authenticated ? (
            <Tooltip title={'Mi lista'} placement='bottom'>
              <Button
                onClick={() => {
                  compareList()
                  if (inList) {
                    setAlert(
                      'El producto producto se elimino de tu lista',
                      'warning'
                    )
                  } else {
                    setAlert('El producto se agregado a tu lista', 'success')
                  }
                }}
              >
                <div>
                  {inList ? (
                    <BsPlusSquareFill className='active2' />
                  ) : (
                    <BsPlusSquare className='icon' />
                  )}
                </div>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={'Debes iniciar sesión'} placement='bottom'>
              <Button onClick={() => {}}>
                <div>
                  <BsPlusSquare className='icon' />
                </div>
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <div className='coleccion'>
        <Link to={`/products/${props.idProducto}/p`}>
          <div className='tampro'>
            <LazyLoadImage
              src={props.imagenPereview}
              fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
              style={{
                marginTop: '20px',
                minWidth: '125px',
              }}
            />
          </div>
        </Link>
      </div>

      {props.sprice > 0 && !props.activoDescuento && !props.rebaja && (
        <div className='product-card-price'>
          <Tooltip
            title={'Dolar américano. Fuente: www.banxico.org.mx/'}
            placement='bottom'
          >
            <div className='tamprecio'>
              ${parseFloat(props.sprice).toFixed(2)} {props.abrevia}{' '}
            </div>
          </Tooltip>
        </div>
      )}
      {props.activoDescuento || props.rebaja ? (
        <div className='product-card-price'>
          <Tooltip
            title={'Dolar américano. Fuente: www.banxico.org.mx/'}
            placement='bottom'
          >
            <div className='tamprecio'>
              ${parseFloat(props.descuentoPrecio).toFixed(2)} {props.abrevia}{' '}
            </div>
          </Tooltip>
          <div className='anterior'>
            <del>
              <span>
                ${parseFloat(props.sprice).toFixed(2)} (Precio Anterior)
              </span>
            </del>
          </div>
        </div>
      ) : null}

      <div className='product-card-nombre'>
        {props.activoDescuento || props.rebaja ? (
          <Link className='nolink' to={`/products/${props.idProducto}/p`}>
            <Tooltip title={props.nombre} placement='bottom'>
              <div className='product-name'>{props.nombre}</div>
            </Tooltip>
          </Link>
        ) : (
          <Link className='nolink' to={`/products/${props.idProducto}/p`}>
            <Tooltip title={props.nombre} placement='bottom'>
              <div className='product-name'>{props.nombre}</div>
            </Tooltip>
          </Link>
        )}
      </div>

      {/* {props.nombre.length <= 30 && <div className='mycart3'></div>} */}
      {resultResponseImg !== null && (
        <div className='variant'>
          {resultResponseImg.map((item, index) => (
            <div
              key={item.id}
              style={{ height: '20%', width: '20%', margin: '2%' }}
            >
              <Link onClick={() => getLink(index)}>
                <div key={item}>
                  <br></br>
                  <LazyLoadImage
                    src={getImgVarS(
                      item.rutaUbicacion,
                      item.id,
                      item.extension
                    )}
                    fallback={'/img/IMAGEN_NO_DISPONIBLE.png'}
                  />
                </div>
                {props.nombre.length <= 28 && <div className='mycart3'></div>}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CardOriginal
