import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'
export const renderMetaPixel = acc => {
  const scripts = []
  scripts.push(
    <script key='function' id='facebook-pixel-script'>
      {`  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1012095503027674');
  fbq('track', '` +
        acc +
        `');
  fbq('track', 'Page');`}
    </script>,
  )
  scripts.push(
    <noscript key='image' id='facebook-pixel-image'>
      {' '}
      {`
    <img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=1012095503027674&ev=PageView&noscript=1"
    />
    `}
    </noscript>,
  )

  return scripts
}
export const accion = acc => {
  return (
    <Helmet>
      <script id='facebook-pixel-script'>
        {`  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1012095503027674');
  fbq('track', '` +
          acc +
          `');
  fbq('track', 'Page');`}
      </script>
      <noscript id='facebook-pixel-image'>
        {`
       <img height="1" width="1" style="display:none"
       src="https://www.facebook.com/tr?id=1012095503027674&ev=PageView&noscript=1"
       />
       `}
      </noscript>
    </Helmet>
  )
}
export const eventoP = () => {
  ReactPixel.init('1012095503027674', {}, { debug: true, autoConfig: false })
  ReactPixel.pageView()
  ReactPixel.fbq('track', 'Page')
  ReactPixel.track('ViewContent', {
    content_name: 'Really Fast Running Shoes',
    content_category: 'Apparel & Accessories > Shoes',
    content_ids: ['1234'],
    content_type: 'product',
    value: 0.5,
    currency: 'USD',
  })
}
