import Base from '../services/Base'
import axios from 'axios'
import { ContactSupportOutlined } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import KeycloakService from '../services/KeycloakService'
// import { useKeycloak } from '@react-keycloak/web'

import Swal from 'sweetalert2'

import { nombre, realm } from '../services/Url'

export const getBySource = filtter => {
  switch (filtter.source) {
    case 'all':
      return getProducts()
    case 'ran':
      return getRandom(filtter.filtro, filtter.user)

    case 'cat':
      return getbycat(filtter.filtro)
    case 'sb':
      return getBySearchBar(filtter)
  }
}
export const getFilteredUnidad = (data, seleccionado, filtro, checks) => {
  // // console.log(getBySearchBarPayload(filtter))

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })

    instance
      .post(
        'Producto/GetFilteredUnidad/' +
          seleccionado +
          '/' +
          filtro +
          '/' +
          checks,
        data
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")

        // console.log(e)
        reject(e)
      })
  })
}
export const getFilteredMarca = (data, seleccionado, filtro, checks) => {
  // // console.log(getBySearchBarPayload(filtter))

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })

    instance
      .post(
        'Producto/GetFilteredMarca/' +
          seleccionado +
          '/' +
          filtro +
          '/' +
          checks,
        data
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")

        // console.log(e)
        reject(e)
      })
  })
}
export const getFilteredFicha = (
  data,
  seleccionado,
  seleccionado2,
  cantidad
) => {
  // // console.log(getBySearchBarPayload(filtter))

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })

    instance
      .post(
        'Producto/GetFilteredAdicional/' +
          seleccionado +
          '/' +
          seleccionado2 +
          '/' +
          cantidad,
        data
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")

        // console.log(e)
        reject(e)
      })
  })
}
const getbycat = idCategoria => {
  // // console.log(getBySearchBarPayload(filtter))

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        id: window.localStorage.getItem('userId'),
      },
    })

    instance
      .get(
        'Producto/GetProductList/' +
          "{'searchType': 2,'idCategory':'" +
          idCategoria +
          "'}"
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")

        // console.log(e)
        reject(e)
      })
  })
}
export const getbyColeccion = idCategoria => {
  // // console.log(getBySearchBarPayload(filtter))

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })

    instance
      .get('')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")

        // console.log(e)
        reject(e)
      })
  })
}
const getBySearchBar = filtter => {
  // // console.log(getBySearchBarPayload(filtter))
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        id: window.localStorage.getItem('userId'),
      },
    })

    instance
      .get(
        'Producto/GetProductList/' +
          "{'searchType': 3,'searchByName':'" +
          filtter.filtro +
          "'}"
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // // console.log("Filtror " + filtter)
        // //   console.log("error buscar prod")
        // console.log(e)
        reject(e)
      })
  })
}

const getBySearchBarPayload = data => {
  return {
    top: 10,
    filtro: data.filtro,
    categorias: [0],
    marcas: [0],
    estatus: '',
    imagenes: 'C',
  }
}

export const getRandom = (fil, id) => {
  // console.log(fil)
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        id: id,
      },
    })

    instance
      .get('Producto/GetFCollectionId/' + fil)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // //console.log("error all "+ Base.baseUrls.almacen)
        // //console.log(e)
        reject(e)
        // console.log(e)
      })
  })
}

export const getProducts = fil => {
  return new Promise((resolve, reject) => {
    // //console.log("todos")
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        id: window.localStorage.getItem('userId'),
      },
    })

    instance
      .get(
        'Producto/GetProductList/' +
          "{'searchType': 1,'indexForAllProducts': -99}"
      )
      .then(response => {
        // // console.log(response.data)
        resolve(response)
      })
      .catch(e => {
        // //console.log("error all "+ Base.baseUrls.almacen)
        // //console.log(e)
        reject(e)
      })
  })
}

export const getResult = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Producto/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const getProductDetailName = name => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Producto/GetName/' + name)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getProductDetail = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        id: window.localStorage.getItem('user'),
      },
    })
    instance
      .get('Producto/GetById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getElement = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('ProductoElemento/GetById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getProductPrice = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('ProductoPrecio/GetActiveByProduct/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // //console.log(e)
        reject(e)
      })
  })
}

export const getRank = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Producto/GetRank')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // //console.log(e)
        reject(e)
      })
  })
}

export const getVariant = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('VarianteProducto/GetByProductId/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

export const getProductVariant = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('VarianteProducto/GetByProductosId/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

export const getTendencia = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        id: window.localStorage.getItem('user'),
      },
    })
    instance
      .get('Producto/GetTendencia')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

// export const getKeysPago = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('TipoPago/GetAll')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }

export const removeTarjeta = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('TarjetaRegalo/Remove', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const Access_Token = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: 'https://api-m.sandbox.paypal.com/',
      headers: {
        CLIENT_ID: data,
        'Content-Type': 'application/x-www-form-urlencoded',

        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        grant_type: 'client_credentials',
      },
    })
    instance
      .post('TarjetaRegalo/Remove', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const updateTarjeta = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('TarjetaRegalo/Update', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getExist = (id, elementos) => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('BannerDescriptivo/Exist/' + id + '/' + elementos)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
        resolve(e)
      })
  })
}
// export const getBannerDesc = (id,elementos) => {
//   return new Promise((resolve, reject) => {
//        const instance = axios.create({
//           baseURL: Base.baseUrls.catalogo,
//           headers: {
//               'Content-Type': 'application/json',
//               'username':'cliente',
//               'nombres':nombre.tienda,
//               "Access-Control-Allow-Origin": "*",
//               "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
//            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
//           }
//       });
//        instance.get('BannerDescriptivo/Banner/'+id+"/"+elementos)
//       .then(response => {
//               resolve(response);

//       }).catch(e => {
// //            console.log(e)
//            reject(e);
//            resolve(e);

//       });
//   });
// };
export const getBannerDesc = async (id, elementos) => {
  const instance = axios.create({
    baseURL: Base.baseUrls.catalogo,
    headers: {
      'Content-Type': 'application/json',
      username: 'cliente',
      nombres: nombre.tienda,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
      'Access-Control-Allow-Headers':
        'Content-Type, Authorization, X-Requested-With',
    },
  })

  try {
    const response = await instance.get(
      `BannerDescriptivo/Banner/${id}/${elementos}`
    )
    return response
  } catch (error) {
    // console.error('There was a problem with your request:', error)
    throw error
  }
}

export const getBannerType = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
        username: 'a',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Banner/GetByType/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getColeccionBanner = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('ColeccionBanner/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
        resolve(e)
      })
  })
}
export const getCarrusel = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('CarruselProducto/GetAllID/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
        resolve(e)
      })
  })
}
// export const getCat = (id) => {
//   return new Promise((resolve, reject) => {
//        const instance = axios.create({
//           baseURL: Base.baseUrls.catalogo,
//           headers: {
//               'Content-Type': 'application/json',
//               'username':'cliente',
//               'nombres':nombre.tienda,
//               "Access-Control-Allow-Origin": "*",
//               "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
//            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
//           }
//       });
//        instance.get('Categoria/GetAllID/'+id)
//       .then(response => {
//               resolve(response);

//       }).catch(e => {
// //            console.log(e)
//            reject(e);
//            resolve(e);

//       });
//   });
// };
export const getCat = async id => {
  const instance = axios.create({
    baseURL: Base.baseUrls.catalogo,
    headers: {
      'Content-Type': 'application/json',
      username: 'cliente',
      nombres: nombre.tienda,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
      'Access-Control-Allow-Headers':
        'Content-Type, Authorization, X-Requested-With',
    },
  })

  try {
    const response = await instance.get(`Categoria/GetAllID/${id}`)
    return response
  } catch (error) {
    // console.error('There was a problem with your request:', error)
    throw error
  }
}

export const getBannercompra = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('BannerCompra/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
        resolve(e)
      })
  })
}

// export const getBannersId = id => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Carrusel/GetAllIdActives/' + id)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//         resolve(e)
//       })
//   })
// }

// export const getKeyTipoName = variable => {
//   // console.log(variable)
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('TipoPagoConfig/GetByIdTipo/' + variable)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }

export const getOpciones = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Opcion/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const saveClientes = obj => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('ClienteForm/Create', obj)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        // handleClose();
        Swal.fire(e.response.data, '', 'error')
        reject(e)
      })
  })
}
export const getCampos = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Campo/GetAllActives/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getForm = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Formulario/Get')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
// export const getEle = id => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('PlantillaElement/GetAll/' + id)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }

// export const getNotB = id => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('CatGeneral/getById/' + id)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }
export const getBanerT = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Text/GetAllActive/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const test = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: 'https://api.clikalo.com/services/Catalogo/api/',
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get(
        'PromocionesDescuento/Up/74.208.113.129/SD3_PETIFUCLIKALO/diciembre$2021/prueba'
      )
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getOferta = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('OfertaProducto/GetAllID/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getTime = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Oferta/GetTime/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getOfertas = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Oferta/GetById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getVer = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Sugerencia/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getBanerR = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('NotContoller/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
// export const getKeyPay = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('TipoPagoConfig/B6DBD78F-B062-4F84-B7EE-0A173785F0A8')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }

export const getMercadoPK = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('TipoPagoConfig/58d35f43-2462-4115-8009-c2feddbd5272')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const CompareListExist = (variable, idproducto) => {
  // console.log(variable)
  // console.log(idproducto)
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('CompareList/GetByIdUsuario/' + variable + '/' + idproducto)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getWishExist = (variable, idproducto) => {
  // console.log(variable)
  // console.log(idproducto)
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('WishList/GetByIdUsuario/' + variable + '/' + idproducto)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const postPregunta = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('ProductoPregunta/Create', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

export const postComentario = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .post('Comentarios/Create', data)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getComentarios = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Comentarios/GetActives/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

export const getPregunta = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('ProductoPregunta/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getCompare = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('CompareList/Create', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getWishList = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('WishList/Create', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const editCompareList = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('CompareList/Active', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const editWishList = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('WishList/Active', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const BannerSuscribe = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('Suscribe/Create', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const searchgetCompareList = (variable, idproducto) => {
  // console.log(variable)
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('CompareList/GetByIdCompare/' + variable + '/' + idproducto)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const searchgetWishList = (variable, idproducto) => {
  // console.log(variable)
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('WishList/GetByWishList/' + variable + '/' + idproducto)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
// export const getProductsList = variable => {
//   // console.log(variable)
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.almacen,
//       headers: {
//         'Content-Type': 'application/json',
//         nombres: nombre.tienda,
//       },
//     })
//     instance
//       .get('CompareList/GetListProducts/' + variable)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }
// export const getProductsWish = variable => {
//   // console.log(variable)
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.almacen,
//       headers: {
//         'Content-Type': 'application/json',
//         nombres: nombre.tienda,
//       },
//     })
//     instance
//       .get('WishList/GetListProducts/' + variable)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }
export const getPreguntas = variable => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('ProductoPregunta/GetByIdProducto/' + variable)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getRespuesta = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Respuesta/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

export const getPrefer = (objeto, Access_token) => {
  // console.log(Access_token)

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: 'https://api.mercadopago.com/',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        Authorization: 'Bearer ' + Access_token,
      },
    })
    instance
      .post('checkout/preferences', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getcon = Access_token => {
  // console.log(Access_token)

  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: 'https://api.mercadopago.com/',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        Authorization: 'Bearer ' + Access_token,
      },
    })
    instance
      .post('v1/payment_methods')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
export const getCatG = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('CatGeneral/getByTable/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getMenuEnabled = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Configuracion/GetById/' + id)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getBlogs = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Blog/GetAllActives')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getMarcas = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Producto/GetMarcas')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getProductoMedida = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('ProductoMedidaActive/GetById/606f8677-ea83-43c2-bf3a-6b273e920f63')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getPromo = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('PromocionesDescuentoProducto/GetByPromo/' + id)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getKey = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Keys/' + id)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getBlogId = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Blog/' + id)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getPlantilla = id => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Plantillas/getName/' + id)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const getPlantillas = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
          'Content-Type, Authorization, X-Requested-With',
      },
    })
    instance
      .get('Plantillas/getActives')
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}
export const CalificacionPost = data => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.almacen,
      headers: {
        'Content-Type': 'application/json',

        nombres: nombre.tienda,
      },
    })
    instance
      .post('ProductoCalificacion/Create', data)
      .then(response => {
        resolve(response)
        // console.log(response)
      })
      .catch(e => {
        // console.log(e)
        reject(e)
      })
  })
}

// export const getLogo = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Logo/bf797a5d-a072-4fc6-8084-836cb5db36cf')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         reject(e)
//       })
//   })
// }

//  export const getDetalle = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//       },
//     })
//     instance
//       .get('Detalle/b8ddb7d8-66f5-401c-b3e8-95f087b19d48')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         reject(e)
//       })
//   })
// }

// export const getKeyNot = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Notificacion/5290139a-6bfb-4f44-b7d2-ed89d92169ef')
//       .then(response => {
//         resolve(response)
//         // console.log(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }

// export const getColor = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Color/7d33785b-e40b-4217-88de-5b11b876c215')
//       .then(response => {
//         resolve(response)
//       })
//       .catch(e => {
//         reject(e)
//       })
//   })
// }

// export const getMenus = () => {
//   return new Promise((resolve, reject) => {
//     const instance = axios.create({
//       baseURL: Base.baseUrls.catalogo,
//       headers: {
//         'Content-Type': 'application/json',
//         username: 'cliente',
//         nombres: nombre.tienda,
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
//         'Access-Control-Allow-Headers':
//           'Content-Type, Authorization, X-Requested-With',
//       },
//     })
//     instance
//       .get('Footer/GetAllMenu')
//       .then(response => {
//         resolve(response)
//         // console.log(response)
//       })
//       .catch(e => {
//         // console.log(e)
//         reject(e)
//       })
//   })
// }
export const Paypal = objeto => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.pedido,
      headers: {
        'Content-Type': 'application/json',
        nombres: nombre.tienda,
      },
    })
    instance
      .post('Pedido/paypal', objeto)
      .then(response => {
        resolve(response)
        // console.log(objeto)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const getKeysParcel = () => {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: Base.baseUrls.catalogo,
      headers: {
        'Content-Type': 'application/json',
        username: 'cliente',
        nombres: nombre.tienda,
      },
    })
    instance
      .get('Keys/GetAll')
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
