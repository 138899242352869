import React, { useContext } from 'react'
import Carousel from 'react-material-ui-carousel'
import autoBind from 'auto-bind'
import BannerDesk from '../../components/Banner/Desk/BannerDesk'
import BannerMobile from '../../components/Banner/Mobile/BannerMobile'
import { Grid } from '@material-ui/core'
import '../../components/Banner/Banner.css'
import useWindowSize from '../../utils/useWindowSize'
// import { COLORS } from '../../Views/Colores'

function BannerExample() {
  const { responsiveSize } = useWindowSize()
  const sliderConfig = {
    autoPlay: true,
    animation: 'slide',
    indicators: true,
    timeout: 300,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
  }

  return (
    <Grid style={{ marginTop: '6%' }}>
      <Carousel
        className='Example'
        autoPlay={sliderConfig.autoPlay}
        animation={sliderConfig.animation}
        indicators={sliderConfig.indicators}
        timeout={sliderConfig.timeout}
        cycleNavigation={sliderConfig.cycleNavigation}
        navButtonsAlwaysVisible={sliderConfig.navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={sliderConfig.navButtonsAlwaysInvisible}
      >
        {
          // items.map((item, index) => {
          //   return  responsiveSize == "sm" ? <BannerMobile item={item} key={index} />  :  <BannerDesk  item={item} key={index}/>;
          // })
        }
      </Carousel>
    </Grid>
  )
}

export default BannerExample
