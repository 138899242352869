import AuthCliente from '../services/AuthCliente'
import KeycloakService from '../services/KeycloakService'

const ValidaExistencia = () => {
  let usuario = KeycloakService.getUserData()
  if (usuario.id !== undefined) {
    let usuarioGoogle = {
      idCliente: usuario.id,
      nombre: usuario.Nombre,
      aPaterno: usuario.Apellido,
      aMaterno: '',
      email: usuario.email,
      token: usuario.token,
    }
    AuthCliente.createUserGoogle(usuarioGoogle)
      .then(r => {
        console.log('ok')
      })
      .catch(e => {
        console.log('noOk')
      })
  }
}
const CheckUser = {
  ValidaExistencia,
}

export default CheckUser
