import { Container, Box } from '@material-ui/core'
import React from 'react'

import mask from './index.css'

function Ofertas() {
  return (
    <></>
    // <div className="section" >
    //     <div className="ventajas">
    //         <h2 className="ventajas-title">Ventajas Integrinox</h2>
    //         <div  >
    //             NUESTRO NOMBRE LO INDICA: INTEGRIDAD E INTEGRALIDAD.
    //             Proveemos a su empresa en un sólo lugar toda la materia prima que requiere en cuanto a acero inoxidable, con el mejor servicio en México. Nos regimos por la integridad, lo que nos convierte en su aliado ideal en producción, con disponibilidad inmediata y gracias a nuestra participación en la ALIANZA INOXIDABLE, ofrecemos entrega en 8 puntos de la república. Adicionalmente, nos distinguimos por administración eficiente de inventarios, que se traduce en mejores precios para su empresa. También cuidamos la presentación y empaque de nuestros productos, por lo que tener que eliminar rayones es cosa del pasado.

    //             Ésto convierte a Integrinox en el proveedor de acero inoxidable más confiable, cómodo, eficiente, cuidadoso y accesible, y en el aliado ideal de su producción.
    //         </div>

    //     </div>
    //     <div className="list-int" >
    //         <ul className="pointer">
    //             <h4 className="centered">Consolidamos su negocio con:</h4>
    //             <li>Entrega inmediata</li>
    //             <li>Servicio excepcional</li>
    //             <li>Agilidad y cercanía</li>
    //             <li>Calidad superior</li>
    //             <li>Precios y términos líderes</li>
    //             <li>Compromiso con su empresa</li>
    //         </ul>
    //     </div>

    // </div>
  )
}

export default Ofertas
