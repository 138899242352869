import axios from 'axios'
import Swal from 'sweetalert2'

import Base from '../services/Base'
import { nombre } from '../services/Url'

const axiosInstance = axios.create({
  baseURL: Base.baseUrls.catalogo,
  headers: {
    'Content-Type': 'application/json',
    username: 'cliente',
    nombres: nombre.tienda,
  },
  //timeout: 1000,
})

// Función para manejar peticiones con cancelación y manejo centralizado de errores
const fetchWithCancel = async (url, signal) => {
  try {
    const response = await axiosInstance.get(url, { signal })
    return response.data
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('Request canceled', e.message)
    } else {
      Swal.fire(
        e.response?.data || 'Catalogo actions',
        'Error desconocido',
        'error'
      )
      throw e
    }
  }
}

export const getDetalleById = async (signal, id) => {
  return fetchWithCancel('Detalle/' + id, signal)
}

export const getSectionsHomeById = async (signal, id) => {
  return fetchWithCancel('PlantillaElement/GetAll/' + id, signal)
}

export const getColor = async signal => {
  return fetchWithCancel('Color/7d33785b-e40b-4217-88de-5b11b876c215', signal)
}

export const getLogo = async signal => {
  return fetchWithCancel('Logo/bf797a5d-a072-4fc6-8084-836cb5db36cf', signal)
}

export const getKeyNot = async signal => {
  return fetchWithCancel(
    'Notificacion/5290139a-6bfb-4f44-b7d2-ed89d92169ef',
    signal
  )
}

export const getTipoPagoById = async (signal, id) => {
  return fetchWithCancel('TipoPagoConfig/' + id, signal)
}

export const getAllPaymentMethods = async signal => {
  return fetchWithCancel('TipoPago/GetAll', signal)
}

export const getKeyTipoName = async (signal, variable) => {
  return fetchWithCancel('TipoPagoConfig/GetByIdTipo/' + variable, signal)
}

export const getMoneda = async signal => {
  return fetchWithCancel('Moneda/750f3976-da97-466f-8a76-c6bca1be9c1b', signal)
}

// HOME

export const getBannersById = async (signal, id) => {
  return fetchWithCancel('Carrusel/GetAllIdActives/' + id, signal)
}

export const getDirecciones = (signal, IdCliente) => {
  return fetchWithCancel('clientes/direccion/get/' + IdCliente, signal)
}

export const getElementosfooter = async signal => {
  return fetchWithCancel('Footer/GetAllMenu', signal)
}

export const getByTable = async (signal, idTable) => {
  return fetchWithCancel('CatGeneral/getByTable/' + idTable, signal)
}
