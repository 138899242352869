import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ApiGeneral from '../../services/ApiGeneral'
import KeycloakService from '../../services/KeycloakService'
import { getDirecciones } from '../../actions/catalogoActions'

import DireccionCard from './DireccionCard'
import DireccionFormulario from './DireccionFormulario'
import MySnackBar from './../MySnackBar/SnackBar'
import PixelR from '../GoogleAnalitycs/PixelR'

const Direccion = ({
  setCurrentDireccion,
  currentDireccion,
  showTitle = true,
  fromOrden = false,
  handleSelectDireccion,
}) => {
  const [abrir, setOpen] = React.useState(false)

  const [direcciones, setDirecciones] = useState([])

  const getdirecciones = () => {
    const usuario = KeycloakService.getUserData()
    ApiGeneral.getDirecciones(usuario.id)
      .then(response => {
        setDirecciones(response.data)
        console.log(response)
      })
      .catch(e => {
        console.error('error data:' + e)
      })
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    const signal = controller.signal
    const usuario = KeycloakService.getUserData()

    getDirecciones(signal, usuario.id)
      .then(data => {
        if (isMounted) {
          setDirecciones(data)
        }
      })
      .catch(error => {
        if (isMounted) {
          console.warn('Error fecth direcciones', error)
        }
      })

    return () => {
      isMounted = false
      controller.abort()
    }
  }, [KeycloakService.isLoggedIn])

  const handleCloseDirecciones = () => {
    setOpen(false)
  }
  const [SBopen, setSBOpen] = React.useState(false)
  const handleCloseSB = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSBOpen(false)
  }
  const [message, setMessage] = React.useState('')
  const [type, setType] = React.useState('')
  return (
    <div style={{ width: '100%' }}>
      <PixelR nombres={'Mis direcciones'} />

      <MySnackBar
        Open={SBopen}
        Type={type}
        Message={message}
        HandleClose={handleCloseSB}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 5,
        }}
      >
        {showTitle ? <label>Mis direcciones de envío</label> : <label></label>}

        <>
          <div>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              variant='outlined'
              color='primary'
            >
              Agregar dirección
            </Button>
            <Dialog
              onClose={handleCloseDirecciones}
              aria-labelledby='simple-dialog-title'
              open={abrir}
            >
              <DialogTitle
                style={{ cursor: 'move' }}
                id='draggable-dialog-title'
              >
                <li
                  className='close-icon'
                  style={{ textAlign: 'right' }}
                  onClick={handleCloseDirecciones}
                >
                  ✕
                </li>
                Agregar dirección
              </DialogTitle>
              <DialogContent dividers style={{ padding: 8 }}>
                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                >
                  <Box display='flex' p={1}>
                    <DireccionFormulario
                      getDirecciones={getdirecciones}
                      handleCloseDirecciones={handleCloseDirecciones}
                      setSBOpen={setSBOpen}
                      setMessage={setMessage}
                      setType={setType}
                      haveDirecctions={direcciones.helperData?.length > 0}
                    />
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
        </>
      </div>

      {!direcciones.helperData && <div>Cargando...</div>}

      {direcciones.helperData &&
        direcciones.helperData.map(x => {
          if (x.activo) {
            return (
              <DireccionCard
                key={x.idClienteDireccion}
                direccion={x}
                fromOrden={fromOrden}
                setCurrentDireccion={setCurrentDireccion}
                currentDireccion={currentDireccion}
                getDirecciones={getdirecciones}
                setSBOpen={setSBOpen}
                setMessage={setMessage}
                setType={setType}
                handleSelectDireccion={handleSelectDireccion}
              />
            )
          }
          return null
        })}
    </div>
  )
}

export default Direccion
